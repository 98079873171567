import PropTypes from 'prop-types'
import styled from 'styled-components'

import { anchorStyle } from './GlobalStyle'
import Button from './Button'
import Text from './Text'

const HeroCTA = (props) => {
  const {
    buttonContent,
    buttonHref,
    buttonColor,
    buttonMobileColor,
    buttonTip,
    className,
    inviteCodeHref,
    hideInviteLink,
    onClick
  } = props

  return (
    <>
      <FlexBox hideInviteLink={hideInviteLink}>
        <StyledButton
          className={className}
          buttonContent={buttonContent}
          buttonHref={buttonHref}
          color={buttonColor}
          mobileColor={buttonMobileColor}
          onClick={onClick}
          testID="hero-button"
        />
        {!hideInviteLink && <Anchor href={inviteCodeHref} data-testid="invite-code-link">I HAVE AN INVITE CODE</Anchor>}
      </FlexBox>
      <ButtonTip size="small" hideInviteLink={hideInviteLink}>{buttonTip}</ButtonTip>
    </>
  )
}

const FlexBox = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: ${ ({ hideInviteLink }) => (hideInviteLink ? '0 0 12px' : '0 0 48px') };
  gap: 32px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    flex-direction: row;
    margin: ${ ({ hideInviteLink }) => (hideInviteLink ? '0 0 12px' : '0 0 24px') };
    gap: 48px;
  }
`

const StyledButton = styled(Button)`
  z-index: 2;
  width: 100%;
  padding: 20px 18px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: initial;
    padding: 22px 32px;
  }
`

const ButtonTip = styled(Text)`
  width: ${ ({ hideInviteLink }) => (hideInviteLink ? '100%' : '200px') };
  margin: 0 auto;
  text-align: center;
  opacity: ${ ({ hideInviteLink }) => hideInviteLink && 0.7 };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: initial;
    margin: 0;
    text-align: left;
  }
`

const Anchor = styled.a`
  ${ anchorStyle }
`

HeroCTA.defaultProps = {
  buttonContent: 'Check my rate',
  buttonHref: '',
  buttonColor: '',
  buttonMobileColor: null,
  className: '',
  inviteCodeHref: '/apply',
  hideInviteLink: false,
  onClick: null,
}

HeroCTA.propTypes = {
  buttonContent: PropTypes.string,
  buttonHref: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonMobileColor: PropTypes.string,
  buttonTip: PropTypes.string.isRequired,
  className: PropTypes.string,
  inviteCodeHref: PropTypes.string,
  hideInviteLink: PropTypes.bool,
  onClick: PropTypes.func,
}

export default HeroCTA
